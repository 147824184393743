import React, {Component} from "react"
import Helmet from 'react-helmet'
import { graphql} from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Remarkable } from 'remarkable'
import TitleBanner from '../components/TitleBanner'
// import Contact from '../components/Contact'
import Layout from "../components/Layout"
import { Container } from '../components/Theme'

import slide1Image from '../../static/assets/phone.jpg'

const md = new Remarkable();

const ContainerLeft = styled(Container)`
display: grid;
grid-template-columns: 2fr 1fr;
grid-gap: 2rem;
@media(max-width:450px){
  grid-template-columns: 1fr;
}
`

const ContainerRight = styled(Container)`
display: grid;
grid-template-columns: 1fr 2fr;
grid-gap: 2rem;
@media(max-width:450px){
  grid-template-columns: 1fr;
}
`

export default class AboutLayout extends Component {

  render(){

    // Pull data from graphql
    const {markdownRemark: page} = this.props.data;
    

    return(
      <Layout>
        <Helmet
          title={`About Metro Communications News`}
          meta={[
            { name: 'description', content: page.excerpt },
            { name: 'keywords', content: `` },
          ]}
        />
        <TitleBanner title={'About Us'} image={slide1Image} />
        <ContainerLeft>
          <div dangerouslySetInnerHTML={{__html: page.html}} />
          <Img fluid={page.frontmatter.image1.childImageSharp.fluid} alt={`About Us Image 1`}/>
        </ContainerLeft>
        <ContainerRight>
          <Img fluid={page.frontmatter.image2.childImageSharp.fluid} alt={`About Us Image 2`}/>
          <div dangerouslySetInnerHTML={{__html: md.render(page.frontmatter.panel2)}} />
        </ContainerRight>
        <ContainerLeft>
          <div dangerouslySetInnerHTML={{__html: md.render(page.frontmatter.panel3)}} />
          <Img fluid={page.frontmatter.image3.childImageSharp.fluid} alt={`About Us Image 3`}/>
        </ContainerLeft>
      </Layout>
    )
  }
}

export const aboutTemplateQuery = graphql`
  query aboutTemplateQuery($id: String!) {
    markdownRemark(id: {eq: $id}){
      id
      excerpt
      html
      frontmatter {
        panel2
        panel3
        image1{
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image2{
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image3{
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`